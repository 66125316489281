import React, { useState } from "react";
import EligibilityFilesTable from "./EligibilityFilesTable";
import MfrHeader from "./MfrProfileHeader";
import NetworkCountsBarChart from "./NetworkCountsBarChart";
import WACLineChart from "./WACLineChart";
import { capitalizeObjectKeys } from "../utils/Object";
import Tile from "../Base/Tile";
import CpAndCeMap from "./CpAndCeMap";

type ManufacturerProfileProps = {
  mfr_logo: string;
  mfr_props: {
    name: string;
    start_date: string;
    annc_date: string;
    ndcs: number;
    networks: string[];
    policy_document_link: string;
    ces_with_pricing: { retail?: number };
    cps_with_pricing: { retail?: number };
    eligibility_files: any[];
    wac: {
      total: number;
      total_since_enforcement: number;
      inhouse: number;
      inhouse_since_enforcement: number;
      cp: number;
      cp_since_enforcement: number;
      inhouse_12_months: [{ month: string; value: number }];
      cp_12_months: [{ month: string; value: number }];
    };
    state_cps_and_ces_count: {
      [key: string]: {
        covered_entities: {
          grantee: number;
          hospital: number;
        };
        contracted_pharmacies: number;
      };
    };
  };
};

const ManufacturerProfile = ({
  mfr_logo,
  mfr_props,
}: ManufacturerProfileProps) => {
  const [wacMonthsRange, setWacMonthsRange] = useState(12);

  const wacLineChartData =
    wacMonthsRange == 12
      ? {
          inhouse: mfr_props.wac.inhouse_12_months,
          cp: mfr_props.wac.cp_12_months,
        }
      : {
          inhouse: mfr_props.wac.inhouse_12_months.slice(-3),
          cp: mfr_props.wac.cp_12_months.slice(-3),
        };

  const wacRangeButtons = (
    <div className="btn__option__group">
      <button
        onClick={() => setWacMonthsRange(12)}
        className={
          wacMonthsRange === 12
            ? "btn btn--tiny btn--selected"
            : "btn btn--tiny"
        }
      >
        Last 12 Months
      </button>
      <button
        onClick={() => setWacMonthsRange(3)}
        className={
          wacMonthsRange === 3 ? "btn btn--tiny btn--selected" : "btn btn--tiny"
        }
      >
        Last 3 Months
      </button>
    </div>
  );

  return (
    <div>
      <MfrHeader
        mfr_logo={mfr_logo}
        name={mfr_props.name}
        networks={mfr_props.networks}
        ndcs={mfr_props.ndcs}
        start_date={mfr_props.start_date}
        annc_date={mfr_props.annc_date}
        policy_document_link={mfr_props.policy_document_link}
        wac={mfr_props.wac}
      />
      <div className="content__caddy">
        <div className="dashboard__container">
          <Tile>
            <Tile.Header className="flex__container flex__container--space-between">
              <div className="d-flex flex-column">
                <Tile.SecondaryText className="t--500">
                  Registrations by States
                </Tile.SecondaryText>
              </div>
              <div className="d-flex flex-column">
                <a
                  className="btn btn--light"
                  href={"manufacturer/download_registrations_by_state"}
                  target="_blank"
                >
                  Download Data
                </a>
              </div>
            </Tile.Header>
            <Tile.Body className="tile--overflow-hidden">
              <CpAndCeMap data={mfr_props.state_cps_and_ces_count} />
            </Tile.Body>
          </Tile>
          <div>
            <div
              className="graph__grid-container"
              style={{
                width: "100%",
                gridTemplateRows: 280,
                rowGap: "2%",
                gridTemplateColumns: "repeat(2, 49%)",
              }}
            >
              <Tile>
                <Tile.Header>
                  <div className="d-flex flex-column">
                    <Tile.SecondaryText className="t--500">
                      Covered Entities
                    </Tile.SecondaryText>
                    <Tile.TertiaryText>CEs with 340B pricing</Tile.TertiaryText>
                  </div>
                </Tile.Header>
                <Tile.Body>
                  <NetworkCountsBarChart
                    data={capitalizeObjectKeys(mfr_props.ces_with_pricing)}
                  />
                </Tile.Body>
              </Tile>
              <Tile>
                <Tile.Header>
                  <div className="d-flex flex-column">
                    <Tile.SecondaryText className="t--500">
                      Contract Pharmacies
                    </Tile.SecondaryText>
                    <Tile.TertiaryText>CPs with 340B pricing</Tile.TertiaryText>
                  </div>
                </Tile.Header>
                <Tile.Body>
                  <NetworkCountsBarChart
                    data={capitalizeObjectKeys(mfr_props.cps_with_pricing)}
                  />
                </Tile.Body>
              </Tile>
            </div>
            <div
              className="graph__grid-container"
              style={{
                width: "100%",
                gridTemplateRows: 280,
                gridTemplateColumns: "100%",
                marginTop: 18,
              }}
            >
              <Tile>
                <Tile.Header className="justify-content-between padding-bottom-0">
                  <div className="d-flex flex-column gap-1">
                    <Tile.SecondaryText className="t--500">
                      CP & In-House WAC
                    </Tile.SecondaryText>
                  </div>
                  <div className="d-flex align-items-start gap-2">
                    {wacRangeButtons}
                  </div>
                </Tile.Header>
                <Tile.Body>
                  <WACLineChart data={wacLineChartData} />
                </Tile.Body>
              </Tile>
            </div>
          </div>
        </div>

        <div className="tabs__container" style={{ marginTop: 180 }}>
          <p className="tabs__item tabs__item--active">Eligibility Files</p>
        </div>
        <EligibilityFilesTable
          eligibility_files={mfr_props.eligibility_files}
        />
      </div>
    </div>
  );
};

export default ManufacturerProfile;
