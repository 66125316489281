import React, { useState, useEffect } from "react";
import _ from "lodash";

import Slider from "@mui/material/Slider";
import Notice from "./Notice";

const SliderForm = (props) => {
  const [value, setValue] = useState(props.value);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const submitForm = () => {
    setNotice({
      kind: "success",
      open: true,
      message: "Success - OVI threshold settings have been updated",
    });

    // fetch(props.path, {
    //   method: "PUT",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    //   },
    //   body: JSON.stringify({
    //     value: value,
    //   }),
    // })
    //   .then((res) => {
    //     if (res.status >= 200 && res.status <= 299) {
    //       return res.json();
    //     } else {
    //       throw Error(res.statusText);
    //     }
    //   })
    //   .then((data) => {
    //     setNotice({
    //       kind: "success",
    //       open: true,
    //       message: data.notice,
    //     });
    //   })
    //   .catch((error) => {
    //     setNotice({
    //       kind: "error",
    //       open: true,
    //       message: "Oh no - it looks like something went wrong, please try again.",
    //     });
    //   });
  };

  return (
    <div style={{ width: "50%" }}>
      <Slider
        defaultValue={value}
        value={value}
        step={5}
        marks
        onChange={(e) => setValue(e.target.value)}
        onChangeCommitted={submitForm}
        aria-label="Default"
        valueLabelDisplay="auto"
      />
      <Notice details={notice} setNoticeState={setNotice} />
    </div>
  );
};

export default SliderForm;
