import React, { useState } from "react";

import Notice from "./Notice";

const SelectForm = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [value, setValue] = useState(props.value);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const renderDropdownLabel = () => {
    return value;
  };

  const submitForm = (option) => {
    fetch(props.path, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({
        value: option,
      }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        setValue(option);
        setNotice({
          kind: "success",
          open: true,
          message: data.notice,
        });
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "Oh no - it looks like something went wrong, please try again.",
        });
      });
  };

  const handleOptionClick = (option) => {
    submitForm(option);
  };

  const renderSelectedOption = (option, i) => {
    return (
      <div
        key={i}
        className="select__menu__item select__menu__item--selected"
        // onClick={() => handleFilter(option.value)}
      >
        <div>{option}</div>
        <div className="select__menu__item__selected__icon">
          <i className="solid solid-budicon-check-ui" />
        </div>
      </div>
    );
  };

  const renderOption = (option, i) => {
    return (
      <div key={i} className="select__menu__item" onClick={() => handleOptionClick(option)}>
        {option}
      </div>
    );
  };

  const renderOptions = () => {
    return props.options.map((option, i) => {
      if (value === option) {
        return renderSelectedOption(option, i);
      } else {
        return renderOption(option, i);
      }
    });
  };

  const renderDropdown = () => {
    if (!menuOpen) return;

    return <div className="select__menu">{renderOptions()}</div>;
  };

  return (
    <div className="d-flex align-items-center">
      <div className="select" onClick={() => setMenuOpen(!menuOpen)}>
        <div className="select__label"> {renderDropdownLabel()} </div>
        {renderDropdown()}
        <div className="select__icon">
          <i className="solid solid-budicon-chevron-bottom" />
        </div>
      </div>
      <Notice details={notice} />
    </div>
  );
};

export default SelectForm;
