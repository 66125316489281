import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { currencyWithCommas } from "../../utils/NumberFormatter";

type Series = {
  data: Array<{ x: string; y: number }>;
};

type BalanceChartProps = {
  data: Series[];
};

const ProductBalanceHistoryChart = ({ data }: BalanceChartProps) => {
  return (
    <ResponsiveLine
      data={
        data && data.length
          ? [{ id: Date.now(), data: data[0].data.slice(-12) }]
          : []
      }
      margin={{ top: 12, right: 10, bottom: 20, left: 90 }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      curve="cardinal"
      areaBaselineValue={-100}
      crosshairType="x"
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 5,
        format: ">-$,",
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 9,
        tickRotation: 0,
      }}
      enableGridX={false}
      enableGridY={true}
      gridYValues={5}
      colors={["#3246d3"]}
      theme={{
        textColor: "#718291",
      }}
      enablePoints={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "seriesColor" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[]}
      enableArea={true}
      tooltip={(points) => {
        return (
          <div
            style={{
              padding: 2,
              color: "#ffffff",
              background: "#222222",
            }}
          >
            <strong>
              {points.point.data.x.toString()}:{" "}
              {`${currencyWithCommas(points.point.data.y)}`}
            </strong>
          </div>
        );
      }}
      defs={[
        {
          id: "gradientC",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "#3246d3" },
            { offset: 100, color: "rgba(255, 255, 255, 0.0)" },
          ],
        },
      ]}
      fill={[{ match: "*", id: "gradientC" }]}
    />
  );
};

export default ProductBalanceHistoryChart;
