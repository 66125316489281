import React from "react";
import { ResponsiveLine, Serie } from "@nivo/line";
import {
  abbreviatedNumber,
  currencyWithCommas,
} from "../utils/NumberFormatter";

type WACLineChartProps = {
  data: {
    inhouse: { month: string; value: number }[];
    cp: { month: string; value: number }[];
  };
};

const WACLineChart = ({ data }: WACLineChartProps) => {
  const formattedData: Serie[] = [];

  if (data.inhouse.length) {
    formattedData.push({
      id: "In-House WAC",
      data: data.inhouse.map((d) => ({
        x: d.month,
        y: d.value,
      })),
    });
  }

  formattedData.push({
    id: "Contract Pharmacy WAC",
    data: data.cp.map((d) => ({
      x: d.month,
      y: d.value,
    })),
  });

  return (
    <ResponsiveLine
      data={formattedData}
      colors={data.inhouse.length ? ["#8957ee", "#35d2fc"] : ["#35d2fc"]}
      margin={{ top: 38, right: 16, bottom: 24, left: 40 }}
      isInteractive
      axisLeft={{
        format: (v) => abbreviatedNumber(v),
        tickValues: 4,
        tickSize: 0,
        tickPadding: 15,
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
      }}
      enableArea
      enablePoints={false}
      useMesh
      enableGridX={false}
      legends={[
        {
          anchor: "top-left",
          direction: "row",
          symbolShape: "circle",
          itemDirection: "left-to-right",
          itemWidth: 150,
          itemHeight: 20,
          symbolSize: 6,
          translateY: -40,
          translateX: -40,
        },
      ]}
      theme={{
        legends: {
          text: { fontSize: 10 },
        },
        axis: {
          ticks: {
            text: { fontSize: 10, textAlign: "right" },
          },
        },
      }}
      tooltip={(points) => {
        return (
          <div>
            {points.point.data.x}: {currencyWithCommas(points.point.data.y)}
          </div>
        );
      }}
      defs={[
        {
          id: "inhouse",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "#8957ee" },
            { offset: 100, color: "rgba(137, 87, 238, 0.1)" },
          ],
        },
        {
          id: "cp",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "#35d2fc" },
            { offset: 100, color: "rgba(53, 210, 252, 0.1)" },
          ],
        },
      ]}
      fill={[
        { match: { id: "In-House WAC" }, id: "inhouse" },
        {
          match: { id: "Contract Pharmacy WAC" },
          id: "cp",
        },
      ]}
    />
  );
};

export default WACLineChart;
